<template>
	<div class="catch container mx-auto">
		<section
			class="catch-top"
			v-if="firstSlider?.images?.length > 0"
		>
			<img
				:src="firstSlider?.images[0]?.url"
				:alt="firstSlider?.title"
			>
			<!-- <div class="w-full h-full flex flex-col justify-between lg:flex-none">
				<img
					class="mobile-top block lg:hidden mx-auto"
					src="../assets/img/mobile-catch.png"
					alt=""
				>
				<img
					class="mobile-title block lg:hidden mx-auto"
					src="../assets/img/mobile-catch-title.svg"
					alt=""
				>
			</div> -->
		</section>
		<section class="catch-middle">
			<div class="catch-hr-1">
				<img
					src="../assets/img/catch-hr-1.jpg"
					alt=""
				>
			</div>
			<!-- catch introduction -->
			<div class="catch-intro lg:container mx-auto flex flex-col lg:flex-none lg:grid lg:grid-cols-7 gap-4 lg:gap-16 relative">
				<div class="intro-image lg:col-span-4 lg:row-span-1">
					<!-- TODO: make a intro slider block then place image here -->
					<img
						class="max-w-xs lg:max-w-full object-contain"
						:src="secondSlider?.images[1]?.url"
						alt=""
					>
					<!-- <img
						class="block lg:hidden"
						src="../assets/img/mobile-catch-middle.png"
						alt=""
					> -->
				</div>
				<div class="intro-content container mx-auto lg:col-span-3 flex flex-col justify-center order-first lg:order-none lg:px-0 relative 2xl:pr-16 lg:py-24">
					<div class="intro-content-title part-title">
						<img
                            class="hidden lg:block"
							:src="secondSlider?.images[0]?.url"
							alt=""
						>
					</div>

					<p
						class="intro-content-text font-body text-base lg:text-md leading-7 lg:leading-loose catch-text text-left order-first lg:order-none"
						v-html="secondSlider?.title"
					></p>
				</div>
			</div>
			<!-- catch features -->
			<div class="catch-features container mx-auto flex flex-col lg:flex-none lg:grid lg:grid-cols-7 gap-4 lg:gap-16">
				<div class="catch-mobile-title flex lg:hidden justify-center">
					<!-- <img
						src="../assets/img/mobile-catch-feature.svg"
						alt=""
					> -->
				</div>
				<div class="features-left lg:h-auto col-span-4 hidden lg:flex flex-row lg:flex-col justify-between">
					<img
						:src="featureImg?.images[1]?.url"
						alt=""
					>
				</div>
				<div class="features-right flex flex-col gap-2 lg:gap-4 col-span-3 lg:px-0  2xl:pr-16 lg:py-24">
					<div class="feature-title part-title">
						<img
							class="hidden lg:block"
							:src="featureImg?.images[0]?.url"
							alt=""
						>
						<img
							class="mx-auto block lg:hidden mobile-title-img"
							src="../assets/img/mobile-catch-feature.svg"
							alt=""
						>

					</div>
					<div
						class="feature"
						v-for="item in features.items"
						:key="item.id"
					>
						<div class="feature-cover lg:hidden">
							<div class="feature-cover-inner">
								<img
									:src="item?.coverImage?.url"
									class=""
									alt=""
								>
							</div>
						</div>
						<div class="feature-content flex flex-col lg:flex-row items-center">
							<div class="feature-content-header-wrapper flex flex-col lg:flex-row items-start lg:items-center">
								<h2 class="feature-content-header font-title text-2xl xl:text-3xl">{{ item?.name }}</h2>
								<span></span>
							</div>
							<p
								class="feature-content-text font-body text-base lg:text-md leading-relaxed catch-text"
								v-html="item?.contents"
							></p>
						</div>
					</div>
				</div>
			</div>
			<!-- catch flow -->
			<div class="catch-flow container mx-auto flex flex-col items-center lg:grid-flow-row lg:flex-none lg:grid lg:grid-rows-6 lg:grid-cols-2 lg:px-4 2xl:px-16 gap-0 2xl:gap-16">
				<div class="catch-flow-title catch-mobile-title part-title lg:row-span-1">
					<img
						class="mx-auto hidden lg:block mb-16 object-contain"
                        style="max-height: 150px;"
						:src="flowImg?.images[1]?.url"
						alt=""
					>
					<img
						class="mx-auto hidden lg:block"
						:src="flowImg?.images[0]?.url"
						alt=""
					>
					<img
						class="mx-auto block lg:hidden mobile-title-img"
						src="../assets/img/mobile-catch-flow.svg"
						alt=""
					>

				</div>
				<div
					class="flow-item flex flex-col lg:grid lg:grid-cols-2 lg:p-4"
					v-for="item in flow.items"
					:key="item.id"
				>
					<div class="flow-item-cover lg:col-span-1">
						<img
							:src="item?.coverImage?.url"
							alt=""
						>
					</div>
					<div class="flow-item-content flex flex-col lg:col-span-1 text-left font-body text-base lg:text-md tracking-widest leading-loose">
						<div class="item-wrapper flex flex-col justify-center mx-0 my-auto">
							<div class="item-header mb-4">
								<img
									:src="item?.images[0]?.url"
									alt=""
								>

							</div>
							<p
								class="item-text"
								v-html="item?.contents"
							>
							</p>
						</div>
					</div>
				</div>

			</div>
			<div class="catch-hr-1">
				<img
					src="../assets/img/catch-hr-1.jpg"
					alt=""
				>
			</div>

		</section>
		<!-- catch plan -->
		<section class="catch-plan">
			<div class="plan-wrapper container mx-auto flex flex-col lg:flex-row gap-4 lg:gap-0">
				<div class="catch-mobile-title flex lg:hidden justify-center">
					<img
                        class="mobile-title-img"
						src="../assets/img/mobile-catch-plan.svg"
						alt=""
					>
				</div>
				<div class="plan-items flex flex-col lg:flex-row lg:px-0">
					<img
						class="hidden 3xl:block object-contain"
						src="../assets/img/catch-plan-img-1.png"
						alt=""
					>
					<div
						class="plan flex flex-col relative justify-center items-center text-left"
						v-for="item in plan.items"
						:key="item.id"
						:style="{backgroundImage: 'url(' + item.coverImage?.url + ')'}"
					>

						<div class="plan-header lg:text-base xl:text-md 2xl:text-xl font-bold leading-tight tracking-widest flex flex-col items-start lg:flex-1 lg:justify-end">
							<h2 class="plan-title-name">{{ item?.name }}</h2>
							<h1 class="lg:text-xl xl:text-2xl 2xl:text-4xl plan-title-price font-title">{{ numberWithCommas(item?.price) }} <span class="text-lg">元</span></h1>

						</div>
						<div class="plan-content-text lg:text-base font-normal leading-relaxed tracking-widest flex justify-center lg:flex-1">
							<p v-html="'<p>-</p>' + item?.contents"></p>
						</div>
						<!-- 
						<div class="plan-content lg:text-base xl:text-md leading-relaxed tracking-widest flex justify-center">
						</div> -->
					</div>
					<img
						class="hidden 3xl:block object-contain"
						src="../assets/img/catch-plan-img-2.png"
						alt=""
					>
				</div>
			</div>
		</section>
		<div class="catch-hr-1">
			<img
				src="../assets/img/catch-hr-1.jpg"
				alt=""
			>
		</div>
		<section class="catch-bottom">
			<!-- catch faq -->
			<div class="catch-faq py-8 lg:py-24 px-8 lg:px-12 xl:px-28 mx-auto flex flex-col lg:grid grid-cols-7 h-full gap-8">
				<div class="catch-mobile-title lg:hidden flex justify-center">
					<img
                        class="mobile-title-img"
						src="../assets/img/mobile-catch-faq.svg"
						alt=""
					>
				</div>
				<div class="faq-left col-span-3 hidden lg:flex flex-row lg:flex-col justify-between">

					<div class="faq-images part-title flex flex-row lg:flex-col justify-between h-full py-16 lg:py-0">
						<template v-for="(image, index) in faqImages?.images">
							<img
								v-if="index > 0"
								alt=""
								:key=image.id
								:src="image?.url"
							>
						</template>
					</div>
				</div>
				<div class="faq-right col-span-4 px-0 lg:px-4">
					<div class="faq-content-title part-title mx-auto">
						<img
                            class="hidden lg:block"
							:src="faqImages?.images[0]?.url"
							alt=""
						>
					</div>
					<div
						v-for="item in faq?.faqItems"
						class="faq"
						:key="item.id"
					>
						<button
							class="faq-question font-header text-left text-md lg:text-md xl:text-lg leading-relaxed lg:leading-loose tracking-wider cursor-pointer w-full p-0"
							@click="expandFaq(item.id)"
						>
							<h4>{{ item.question }}</h4>
						</button>
						<transition name="fade-out">
							<div
								class="faq-answer text-left text-base lg:text-base xl:text-md font-header tracking-wider mt-0 leading-loose"
								v-if="expandFaqIds.includes(item.id)"
							>
								<p v-html="item.answer"></p>
							</div>
						</transition>

					</div>
				</div>
			</div>
			<div class="footer leading-loose pt-12 xl:flex-row flex flex-col items-center justify-between lg:container lg:mx-auto lg:px-auto px-4 font-body tracking-widest text-left lg:h-auto pb-6">
				<div class="footer-title py-2 lg:py-0 lg:my-auto flex justify-center lg:flex-none text-left tracking-wide2x lg:tracking-wide">
					<div>
						<router-link to="/">
							{{ content.title }}
						</router-link>
					</div>
					<div
						v-if="isMobile"
						class="footer-social flex justify-center items-center"
					>
						<span class="mx-2"></span>
						<a
							:href="facebookLink"
							class="footer-facebook block w-6 h-6 mx-2"
						></a>
						<a
							:href="instagramLink"
							class="footer-instagram block w-6 h-6"
						></a>
					</div>
				</div>
				<div class="footer-address text-sm sm:text-base tracking-wide text-left flex justify-center"><a
						:href="mapUrl.embedUrl"
						:title="mapUrl.description"
						target="_blank"
					>{{ content.subTitle }} </a><span v-if="isMobile"> &nbsp;&nbsp;|&nbsp;&nbsp;{{ content.description }}</span></div>

				<div
					v-if="!isMobile"
					class="footer-phone-number text-sm sm:text-base tracking-wide text-left text-about"
				>{{ content.description }}</div>
				<div class="footer-resolution hidden lg:block text-sm sm:text-base tracking-wide text-left">建議環境：Chrome@1920x1080</div>
				<div class="footer-copyrights flex justify-center lg:flex-none my-auto text-about text-xs sm:text-base text-left tracking-wide sm:tracking-wide leading-normal">{{ content.subDescription }}</div>
				<div
					v-if="chat.loginGreeting != ''"
					class="btn btn-primary tooltip"
                    @click="clickTip"
				>
					<div class="top custom-greeting">
						<p>{{chat.loginGreeting}}</p>
						<i></i>
					</div>
				</div>
			</div>
		</section>
	</div>
</template>

<script>
import { ref, reactive, onMounted, computed } from "vue";
import { useStore } from "vuex";
import { fetchData, fetchApi } from "../utils/fetchApi";
import { numberWithCommas } from "../utils/helpers";

export default {
	setup() {
		const store = useStore();
		const enabledMenuId = store.state.enabledMenuId;
		// define all refs of blocks id
		const firstSliderId = ref(null);
		const secondSliderId = ref(null);
		const featuresId = ref(null);
		const featureImgId = ref(null);
		const flowId = ref(null);
		const flowImgId = ref(null);
		const planId = ref(null);
		const faqId = ref(null);
		const faqImagesId = ref(null);
		const isMobile = computed(() => store.state.isMobile);
		const facebookLink = computed(() => store.state.facebookLink);
		const instagramLink = computed(() => store.state.instagramLink);
		const mapUrlId = ref(null);
		const map = store.state.menuItems.filter((i) => i.index == "/map")[0];
		const mapUrl = reactive({});

		// define all forms reactive fields
		const firstSlider = reactive({ title: "", images: [] });
		const secondSlider = reactive({ title: "", images: [] });
		const features = reactive({
			title: "",
			description: "",
			items: [],
		});
		const featureImg = reactive({ title: "", images: [] });
		const flow = reactive({
			title: "",
			description: "",
			items: [],
		});
		const flowImg = reactive({ title: "", images: [] });
		const plan = reactive({ title: "", description: "", items: [] });
		const faq = reactive({ title: "", description: "", faqItems: [] });
		const faqImages = reactive({ title: "", images: [] });
		const chat = reactive({ loginGreeting: "" });

		// get blocks Id then get blocks content from api
		const idTitleObjArray = [
			{ id: firstSliderId, title: "First Catch Slider" },
			{ id: secondSliderId, title: "Second Catch Slider" },
			{ id: featureImgId, title: "Catch Features Image" },
			{ id: featuresId, title: "Features Block" },
			{ id: flowImgId, title: "Catch Flow Image" },
			{ id: flowId, title: "Catch Flow" },
			{ id: planId, title: "Catch Price List" },
			{ id: faqId, title: "Catch Faq" },
			{ id: faqImagesId, title: "Catch Faq Images" },
		];

		// const numberWithCommas = (num) => {
		// 	return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
		// };

		const expandFaqIds = ref([]);

		const expandFaq = (id) => {
			if (expandFaqIds.value.includes(id))
				expandFaqIds.value = expandFaqIds.value.filter((i) => i != id);
			else expandFaqIds.value.push(id);
		};

        const clickTip = () => {
            document.querySelector('.custom-greeting').classList.add('hidden');
        }

		// footer
		const menuId = "7c0b42fa-5a98-4180-9b47-baf6fa53c1a9";
		const contentId = ref(null);
		const content = reactive({});
		onMounted(async () => {
			await fetchData.menuBlocks(enabledMenuId, idTitleObjArray);
			await fetchData.slider(firstSliderId, firstSlider);
			await fetchData.slider(secondSliderId, secondSlider);
			await fetchData.priceList(featuresId, features);
			await fetchData.slider(featureImgId, featureImg);
			await fetchData.priceList(flowId, flow);
			await fetchData.slider(flowImgId, flowImg);
			await fetchData.priceList(planId, plan);
			await fetchData.faq(faqId, faq);
			await fetchData.slider(faqImagesId, faqImages);
			await fetchApi
				.menuBlocks(menuId)
				.then(
					(blocks) =>
						(contentId.value = blocks.find(
							(b) => b.blockType == "Contents"
						).blockId)
				);
			await fetchApi
				.menuBlocks(map.id)
				.then(
					(blocks) =>
						(mapUrlId.value = blocks.find(
							(b) => b.blockType == "EmbedUrls"
						).blockId)
				);

			await fetchData.content(contentId, content);
			await fetchData.embedUrl(mapUrlId, mapUrl);
			await fetchData.chat(chat);
		});
		return {
			isMobile,
			chat,
			firstSlider,
			secondSlider,
			features,
			featureImg,
			flow,
			flowImg,
			plan,
			faq,
			faqImages,
			content,
			expandFaqIds,
			expandFaq,
			mapUrl,
			facebookLink,
			instagramLink,
			numberWithCommas,
            clickTip
		};
	},
};
</script>

<style lang="scss" scoped>
@import "../assets/scss/styles.scss";

.catch {
	&-top {
		width: 100%;
		height: calc(100vh - 12rem);
		@media (max-width: 1536px) {
			max-height: 500px;
		}
		> img {
			width: 100%;
			height: 100%;
			object-fit: cover;
			object-position: center;
			@media (max-width: 1023px) {
				// height: auto;
				object-position: center;
				object-fit: contain;
			}
			&.mobile-title {
				max-width: 16rem;
				margin-bottom: 4rem;
			}
		}
		@media (max-width: 1023px) {
			height: 40vh;
			height: calc(var(--vh, 1vh) * 40);
		}
		@media (max-width: 640px) {
			height: 32vh;
			height: calc(var(--vh, 1vh) * 32);
		}
        @media (max-width: 450px) {
            height: 20vh;
            height: calc(var(--vh, 1vh) * 20);
        }
	}
	&-middle {
		width: 100%;
		// height: calc(200vh - 34px);
		background: url("../assets/img/catch-bg-blur.png") center top no-repeat;
		background-size: cover;
		background-color: rgba(255, 255, 255, 0.1);
		overflow: hidden;
		@media (max-width: 1023px) {
			height: auto;
			background: transparent;
		}
	}
	&-bottom {
		width: 100%;
		background: url("../assets/img/catch-bg-blur.png") center top no-repeat;
		background-size: cover;
		background-color: rgba(255, 255, 255, 0.1);
		@media (max-width: 1023px) {
			background: none;
		}
	}
	&-intro {
		@apply py-4;
	}
	&-intro,
	&-features,
	&-plan {
		width: 100%;
		height: calc(100vh - 17px);
		// padding-top: 5rem;
		// padding-bottom: 5rem;
		position: relative;
		@media (max-width: 1536px) {
			height: auto;
			// padding-top: 2rem;
			// padding-bottom: 2rem;
		}
	}
	&-intro,
	&-features,
	&-plan {
		height: 100vh;
		position: relative;
		@media (max-width: 1536px) {
			height: auto;
		}
	}
	&-flow {
		width: 100%;
		height: auto;
		padding-top: 5rem;
		padding-bottom: 5rem;
		// gap: 3rem 6rem;
		@media (max-width: 1536px) {
			padding-top: 2rem;
			padding-bottom: 2rem;
		}
	}
	&-plan {
		background: url("../assets/img/catch-plan-bg.jpg") center no-repeat;
		background-size: cover;
		display: flex;
		justify-content: center;
		align-items: center;
		@media (max-width: 1023px) {
			background: transparent;
		}
	}
	&-faq {
		width: 100%;
		padding-top: 5rem;
		padding-bottom: 5rem;
		@media (max-width: 1023px) {
			padding-top: 12rem;
		}
	}
	&-title {
		margin-bottom: 4rem;
		width: 100%;
		img {
			max-width: 500px;
		}
		@media (max-width: 1023px) {
			margin-bottom: 2rem;
		}
	}
	&-text {
		letter-spacing: 0.2rem;

		@media (max-width: 1023px) {
			letter-spacing: 0.1rem;
		}
	}
	&-hr-1,
	&-hr-2 {
		img {
			width: 100%;
		}
		@media (max-width: 1023px) {
			display: none;
		}
	}
}
.intro {
	&-image {
		margin: auto;
		img {
			max-height: 720px;
		}
	}
	&-content {
		&:before {
			position: absolute;
			content: "";
			width: 100%;
			height: 100%;
			top: -8rem;
			left: -15rem;
			background: url("../assets/img/catch-feature-img-3.png") top left
				no-repeat;
			@media (max-width: 1536px) {
				display: none;
			}
		}
	}
}
.features-left,
.plan-left {
	position: relative;
	// padding-right: 2rem;
	img {
		width: 100%;
		height: 100%;
		max-height: 720px;
		object-fit: contain;
		@media (max-width: 1023px) {
			height: auto;
		}
	}
}
.features-right,
.plan-items {
	width: auto;
	height: 100%;
	grid-template-rows: 45% 55%;
}
.features-right {
	display: flex;
	justify-content: center;
	@media (max-width: 1023px) {
		padding-top: 0;
	}
}
.catch-flow {
	&-title {
		img:nth-child(1) {
			transform: scale(1.5);
			margin-top: -9rem;
		}
	}
}
.flow-item {
	@media (max-width: 1023px) {
		max-width: 480px;
        .item-header img {
            display: block;
            max-width: 350px;
            margin: 0 auto;
        }
	}
}
.plan-items {
	position: relative;
	justify-content: center;
	top: 0;
	bottom: 0;
	margin: auto 0;
	@apply font-header;
	@media (max-width: 1023px) {
		flex-direction: row;
		flex-wrap: wrap;
		justify-content: space-between;
		width: 45rem;
	}
	@media (max-width: 767px) {
		flex-direction: column;
		justify-content: center;
		width: 100%;
	}
}
.feature {
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	margin-bottom: 1rem;
	&:nth-child(odd) {
		@media (max-width: 1023px) {
			&:after {
				background: rgba(248, 168, 165, 0.5);
			}
			.feature-content-header {
				color: #ab946a;
			}
			.feature-content span {
				background: #ab946a;
			}
		}
	}
	@media (max-width: 1023px) {
		flex-direction: row;
		padding: 1rem;
		height: 10rem;
		border: 2px solid #ceb689;
	}
	@media (max-width: 640px) {
		height: 15rem;
	}
	&:after {
		content: "";
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background: transparent;
		mix-blend-mode: multiply;
		z-index: 0;
		pointer-events: none;
		@media (max-width: 1023px) {
			background: rgba(230, 209, 170, 0.6);
		}
	}
	&-cover {
		position: relative;
		height: 60%;
		width: 100%;
		z-index: 1;
		@media (max-width: 1023px) {
			height: 100% !important;
			width: 65% !important;
			border: 2px solid #ceb689;
		}
		&-inner {
			width: 100%;
			height: 100%;
			position: relative;
			img {
				width: 110%;
				height: 110%;
				object-fit: contain;
				position: absolute;
				z-index: 2;
				bottom: 0;
			}
			&:before {
				content: "";
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
				margin: auto;
				width: 100%;
				height: 100%;
				background: url("../assets/img/feature-cover-bg.png") center
					no-repeat;
				object-fit: cover;
				opacity: 0.8;
				z-index: 0;
			}
		}
	}
	&-content {
		position: relative;
		width: 100%;
		text-align: left;
		display: flex;
		// flex-direction: column;
		z-index: 1;
		@media (max-width: 1023px) {
			height: 100% !important;
			width: 70% !important;
			padding-left: 2rem;
			justify-content: center !important;
			align-items: start;
			padding-bottom: 0 !important;
			padding-top: 0 !important;
		}
		&-header {
			line-height: 1;
			color: $red;
			font-weight: bold;
			@media (max-width: 1023px) {
				color: #d90e0e;
				@apply font-normal;
			}
		}
		&-text {
			// font-size: 1.1rem !important;
		}
		span {
			background: $red;
			width: 0.1rem;
			height: 4rem;
			margin-left: 1.5rem;
			margin-right: 1.5rem;
			@media (max-width: 1023px) {
				background: #d90e0e;
				margin-left: 0;
				margin-right: 0;
				margin-top: 0.25rem;
				margin-bottom: 0.5rem;
				width: 0.5rem;
				height: 0.1rem;
			}
		}
	}
	&-lg-block {
		.feature-content {
			width: 100%;
			height: 40%;
			justify-content: flex-end;
			padding-bottom: 4rem;

			&-text {
				@media (max-width: 1023px) {
					@apply text-md;
				}
			}
			@media (max-width: 1023px) {
				justify-content: center;
				padding-bottom: 0;
			}
		}
		.feature-cover {
			@media (max-width: 1023px) {
				height: 100%;
				width: 50%;
			}
		}
	}
	&-md-block {
		.feature-cover {
			height: 50%;
			@media (max-width: 1023px) {
				height: 100%;
				width: 50%;
			}
		}
		.feature-content {
			width: 100%;
			height: 50%;
			padding-top: 1rem;
		}
	}
	&-sm-block {
		.feature-cover {
			height: 40%;
			img {
				width: 110%;
				height: 110%;
			}
			@media (max-width: 1023px) {
				height: 100%;
				width: 50%;
			}
		}
		.feature-content {
			width: 100%;
			height: 60%;
			padding-top: 2rem;
		}
	}
}
.plan {
	position: relative;
	border-radius: 50%;
	outline: 5px solid $about;
	width: 22rem;
	height: 22rem;
	padding: 2.5rem;
	margin: 1rem;
	@media (max-width: 1280px) {
		width: 18rem;
		height: 18rem;
		padding: 3rem;
		margin: 2.5rem 1rem;
	}
	@media (max-width: 1023px) {
		width: auto;
        min-width: 280px;
		height: auto;
		border-radius: 0;
		padding: 0;
		margin: 2rem;
		outline-width: 16px;
	}
	@media (max-width: 767px) {
        min-width: 240px;
		&:nth-child(1) {
			&:before {
				position: absolute;
				content: "";
				width: 5rem;
				height: 5rem;
				top: 9rem;
				left: -3rem;
				background: url("../assets/img/mobile-catch-assets-01.png")
					no-repeat;
				background-size: contain;
			}
			&:after {
				position: absolute;
				content: "";
				width: 45rem;
				height: 40rem;
				top: 15rem;
				left: -17rem;
				background: url("../assets/img/mobile-catch-assets-02.png")
					no-repeat;
				background-size: contain;
				z-index: 2;
			}
		}

		&:nth-child(3) {
			&:before {
				position: absolute;
				content: "";
				width: 8rem;
				height: 16rem;
				top: -5rem;
				right: -3rem;
				background: url("../assets/img/mobile-catch-assets-03.png")
					no-repeat;
				background-size: contain;
			}
			// &:after {
			// 	position: absolute;
			//	content: "";
			//	width: 52rem;
			//	height: 45rem;
			//	top: 20rem;
			//	left: -21rem;
			//	background: url("../assets/img/mobile-catch-assets-04.png")
			//		no-repeat;
			//	background-size: contain;
			//	z-index: 2;
		//	}
		}
	}
	&-wrapper {
		justify-content: center;
		align-items: center;
		@media (max-width: 1023px) {
			height: auto;
		}
	}
	&-image {
		position: absolute;
		width: 120%;
		// height: 110%;
		bottom: 0;
	}
	&-header {
		@media (max-width: 1023px) {
			padding: 3rem 0 3rem 0;
		}
	}
	&-title {
		width: 35%;
		padding-left: 2rem;
		line-height: 1.5rem;
		z-index: 1;
		&-name {
			color: $about;
			@media (max-width: 1023px) {
				@apply text-lg;
			}
		}
		&-price {
			color: $white;
			@media (max-width: 1023px) {
				@apply text-2xl;
			}
		}
		@media (max-width: 1023px) {
			font-size: 0.9rem;
			align-items: center !important;
			justify-content: center !important;
			width: 35% !important;
			padding: 0 !important;
		}
	}
	&-content {

		&-text {
			color: $about;
			padding-top: 1rem;
			@media (max-width: 1023px) {
				background: $about;
				color: $secondary;
				min-height: 200px;
				width: 100%;
				border: 1px solid $about;
				outline: 1px solid $about;
			}
		}
	}
	// &-lg-block {
	// 	align-items: flex-start;
	// 	justify-content: center;
	// 	@apply lg:px-8 xl:px-32;
	// 	@media (max-width: 1023px) {
	// 		align-items: center;
	// 	}
	// 	img {
	// 		z-index: 2;
	// 	}
	// 	.plan-title {
	// 		width: 100%;
	// 		padding-left: 0;
	// 		align-items: flex-start;
	// 	}
	// 	.plan-content {
	// 		padding-top: 1rem;
	// 		width: 100%;
	// 		justify-content: flex-start;
	// 	}
	// }
}
.faq {
	/*Hack of Horizontal Borders*/
	background-image: linear-gradient(
		to right,
		#343434 33%,
		rgba(52, 52, 52, 0) 0%
	);
	background-position: bottom;
	background-size: 18px 1px;
	background-repeat: repeat-x;
	@media (max-width: 1023px) {
		background: none;
		border-bottom: 1px solid #bbbbbb;
		&:last-child {
			border-bottom: none;
		}
	}
	&-question {
		padding-top: 1rem;
		padding-bottom: 1rem;
		@apply text-secondary;
		@media (max-width: 1023px) {
			color: #ab946a;
		}
	}
	&-answer {
		padding-bottom: 2rem;
	}
	&:last-child {
		background: none;
		.faq-answer {
			background: none;
		}
	}
	&-images {
		display: flex;
		align-items: center;
		img {
			width: 100%;
		}
	}
}

.mobile-title-img {
    @media (max-width: 1023px) {
        width: 300px !important;
    }
    @media (max-width: 767px) {
        width: 250px !important;
    }
    @media (max-width: 640px) {
        width: 220px !important;
    }
}

.part-title > img.hidden {
    max-height: 50px;
    width: auto;
}
.fade-out-enter-active,
.fade-out-leave-active {
	transition: opacity 0.25s ease-in-out;
}

.fade-out-enter-from,
.fade-out-leave-to {
	opacity: 0;
}

.catch-mobile-title {
    max-height: 180px;
    width: auto;
    margin-bottom: 2rem;
}
</style>
<style lang="scss">
.faq {
	strong {
		color: #e7373c;
	}
}
.feature-content-text {
	strong {
		color: #e7373c;
	}
}
</style>